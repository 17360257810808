import { LineItem } from '@commercelayer/sdk';
import { Order } from '@commercelayer/sdk/lib/resources/orders';
import { useRouter } from 'next/router';

import {
  retrieveGlobalCatalog,
  retrieveGlobalLocale,
  setGlobalCatalog,
  setGlobalLocale,
} from './OrderInformationService.globals';

import { ShopExperience } from 'shared/constants/ShopExperience';
import { useShopExperience } from 'shared/context';
import { getCountryCodeFromISOLocale } from 'shared/i18n/helpers';
import { LOCALE_CODE } from 'shared/infra/contentful/contentful';
import {
  CLAYER_ORDER_ID,
  ORDER_CATALOG,
  ORDER_LOCALE,
  PLACED_ORDER_INFO,
  PLACED_ORDER_PRODUCTS,
  removeItem,
  SALES_CHANNEL_AUTH_TOKEN,
} from 'shared/infra/storage';
import isServer from 'shared/utils/is-server';


export const removeLocalOrderInformation = (): void => {
  try {
    removeItem(SALES_CHANNEL_AUTH_TOKEN);
    localStorage.removeItem(CLAYER_ORDER_ID);
    localStorage.removeItem(ORDER_LOCALE);
    localStorage.removeItem(ORDER_CATALOG);
  } catch (e) {
    if (!isServer) {
      // eslint-disable-next-line no-console
      console.error('Unable to remove order and locale locally!');
    }
  }
};

export const saveLocalOrderInformation = (orderId: string): void => {
  try {
    localStorage.setItem(CLAYER_ORDER_ID, orderId);
    localStorage.setItem(ORDER_LOCALE, retrieveGlobalLocale());
    localStorage.setItem(ORDER_CATALOG, retrieveGlobalCatalog());
  } catch (e) {
    if (!isServer) {
      // eslint-disable-next-line no-console
      console.error('Unable to store order and locale locally!');
    }
  }
};

export const retrieveLocalOrderInformation = (): {
  orderId: string;
  orderLocale: string;
  orderCatalog: ShopExperience;
} => {
  let orderId: string;
  let orderLocale: string;
  let orderCatalog: ShopExperience;
  try {
    orderId = localStorage.getItem(CLAYER_ORDER_ID);
    orderLocale = localStorage.getItem(ORDER_LOCALE);
    orderCatalog = localStorage.getItem(ORDER_CATALOG) as ShopExperience;
  } catch (e) {
    if (!isServer) {
      // eslint-disable-next-line no-console
      console.error('Unable to store order and locale locally!');
    }
  }
  return { orderId, orderLocale, orderCatalog };
};

export const useLocalOrderInformation = (): void => {
  const { locale, pathname } = useRouter();
  const globalAppLocale = locale as LOCALE_CODE;
  // the contentful catalog used is dependent on (has the same value as) the shop experience
  const globalShopCatalog = useShopExperience();

  if (pathname?.includes('/auth/callback')) {
    return;
  }

  // save global reference to our locale
  setGlobalLocale(globalAppLocale);
  // save global reference to our shop experience
  setGlobalCatalog(globalShopCatalog);

  try {
    const {
      orderLocale: locallySavedOrderLocale,
      orderCatalog: locallySavedOrderCatalog,
      orderId,
    } = retrieveLocalOrderInformation();

    const isCountryMismatching =
      !locallySavedOrderLocale ||
      getCountryCodeFromISOLocale(locallySavedOrderLocale) !==
        getCountryCodeFromISOLocale(globalAppLocale);
    const isCatalogMismatching =
      !locallySavedOrderCatalog ||
      locallySavedOrderCatalog !== globalShopCatalog;

    // if the country based on iso locale we have saved the order with is not the same as the one the user is accessing with now
    // or if the product catalog from the saved order is different
    // remove stale order information
    if (orderId && (isCountryMismatching || isCatalogMismatching)) {
      removeLocalOrderInformation();
    }
  } catch (e) {
    if (!isServer) {
      // eslint-disable-next-line no-console
      console.error('Unable to clear local order information!');
    }
  }
};

/**
 * Store order information to be consumed later on the
 * Thank You page after the order is PAID.
 */
export const storeOrderInformationOnSession = (order: Order): void => {
  sessionStorage.setItem(
    PLACED_ORDER_PRODUCTS,
    JSON.stringify(order.line_items),
  );

  sessionStorage.setItem(
    PLACED_ORDER_INFO,
    JSON.stringify({
      id: order.id,
      currency: order.currency_code,
      totalAmount: order.total_amount_float,
      couponCode: order.coupon_code,
    }),
  );
};

export interface ISessionOrderInfo {
  orderInfo: {
    currency: string;
    totalAmount: number;
    couponCode: string;
  };
  orderLineItems: LineItem[];
}

export const retrieveOrderInformationFromSession = (): ISessionOrderInfo => {
  const sessionOrderInfo = sessionStorage.getItem(PLACED_ORDER_INFO);
  const sessionOrderLineItems = sessionStorage.getItem(PLACED_ORDER_PRODUCTS);

  const orderInfo = (
    sessionOrderInfo ? JSON.parse(sessionOrderInfo) : {}
  ) as ISessionOrderInfo['orderInfo'];
  const orderLineItems = (
    sessionOrderLineItems ? JSON.parse(sessionOrderLineItems) : []
  ) as LineItem[];

  return {
    orderInfo,
    orderLineItems,
  };
};
