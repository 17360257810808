import { FC } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { SumUpLogo } from '@sumup/icons';
import { Grid, Row } from '@sumup/circuit-ui';
import { useRouter } from 'next/router';

import { Column } from 'shared/components/Column';
import { WEBSITE_URL_BY_LOCALE } from 'tools/locales';
import { dispatchGoToWebsiteEvent } from 'shared/services/tracker/events';
import { ShoppingCart } from 'shared/components/ShoppingCart';
import { useTypedSelector } from 'shared/store';
import { useShopExperience } from 'shared/context';
import { ShopExperience } from 'shared/constants/ShopExperience';
import { Anchor } from 'src/experiments/odl/components/Anchor';

const HEADER_HEIGHT = 60;
const LOGO_WIDTH = 96;

const Container = styled('header')(
  ({ theme }) => css`
    // set z-index to absolute so the shadows at the bottom won't be hidden
    position: relative;
    z-index: ${theme.zIndex.absolute};

    height: ${HEADER_HEIGHT}px;
    width: 100%;
    box-shadow: var(--cui-border-subtle-disabled) 0px 0px 0px 1px,
      var(--cui-border-subtle-disabled) 0px 0px 1px 0px,
      var(--cui-border-subtle-disabled) 0px 2px 2px 0px;
    display: flex;
    align-items: center;
  `,
);

const StyledCol = styled(Column)(
  ({ theme }) => css`
    ${theme.mq.untilKilo} {
      padding: 0 ${theme.spacings.mega};
    }
  `,
);

/**
 * As discussed in the ticket, Logo will stay unclickable
 * until further verification.
 * Ref: https://sumupteam.atlassian.net/browse/ACQJ-362
 */
export const StyledSumUpLogo = styled(SumUpLogo)`
  width: ${LOGO_WIDTH}px;
  vertical-align: middle;
`;

const StyledAnchor = styled(Anchor)(
  () => css`
    text-decoration: none;
    color: inherit;
    &:active,
    &:visited,
    &:visited:hover,
    &:hover,
    &:focus {
      text-decoration: none;
      color: inherit;
    }
  `,
);

export const ShopHeader: FC<unknown> = () => {
  const router = useRouter();
  const { locale, pathname } = router;

  const websiteUrl = WEBSITE_URL_BY_LOCALE[locale] || 'sumup.com';
  const iconIsClickable = pathname.includes('product-selection');
  const isDashboardPage = useShopExperience() === ShopExperience.DASHBOARD;

  const { products, orderDetails } = useTypedSelector((state) => state.order);

  return (
    <Container>
      <Grid>
        <Row>
          <StyledCol span="10">
            {iconIsClickable ? (
              <StyledAnchor
                href={`https://${websiteUrl}`}
                onClick={() => {
                  dispatchGoToWebsiteEvent({ link: websiteUrl });
                }}
                aria-label="logo"
              >
                <StyledSumUpLogo />
              </StyledAnchor>
            ) : (
              <StyledSumUpLogo />
            )}
          </StyledCol>
          <StyledCol span="2">
            {isDashboardPage ? (
              <ShoppingCart quantity={products.length} id={orderDetails.id} />
            ) : null}
          </StyledCol>
        </Row>
      </Grid>
    </Container>
  );
};
