import { Tracker } from './tracker';

export {
  dispatchPageViewEvent,
  dispatchAddProductEvent,
  dispatchAddProductFailureEvent,
  dispatchChangeProductQuantityEvent,
  dispatchChangeProductQuantityFailureEvent,
  dispatchRemoveProductEvent,
  dispatchRemoveProductFailureEvent,
  dispatchGoToCheckoutEvent,
  dispatchSignupEvent,
  dispatchSignupFailureEvent,
  dispatchLoginEvent,
  dispatchLoginFailureEvent,
  dispatchPurchaseEvent,
  dispatchPurchaseFailureEvent,
  dispatchGoToActivationEvent,
  dispatchCreateCustomer,
  dispatchCreateCustomerFailure,
  dispatchCreateCheckout,
  dispatchCreateCheckoutFailure,
  dispatchOrderPlacedEvent,
  dispatchOrderPlacementFailureEvent,
  dispatchPaymentCallbackInvokedEvent,
  dispatchOptimizelyInitEvent,
  dispatchOptimizelyInitFailureEvent,
  dispatch404Event,
  dispatch500Event,
  dispatchOrderStatusEvent,
  dispatchOrderStatusFailedEvent,
  dispatchEcomClientInit,
  dispatchEcomClientInitFailure,
  dispatchGoToWebsiteEvent,
  dispatchSkipShopEvent,
  dispatchBusinessAccountLearnMoreOpenEvent,
  dispatchBusinessAccountLearnMoreCloseEvent,
  dispatchBusinessAccountSelectedEvent,
  dispatchBusinessAccountUnselectedEvent,
  dispatchBusinessAccountConditionsModalOpenEvent,
  dispatchBusinessAccountConditionsModalCloseEvent,
} from './events';

export const tracker = Tracker();
