import { Anchor as CircuitAnchor, AnchorProps } from '@sumup/circuit-ui';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { FC, useContext } from 'react';

import { ODLExperimentContext } from '../context';

const BlackLink = styled(CircuitAnchor)(
  ({ theme }) => css`
    font-weight: ${theme.fontWeight.bold};
    color: ${theme.colors.n900};
    border-radius: 0;
    border: 2px solid transparent;
    &:hover {
      color: #1a1a1a;
    }
    &:active {
      color: ${theme.colors.n900};
    }
    &:focus-visible {
      border: 1px solid ${theme.colors.n900};
      box-shadow: none;
    }
  `,
);

type Props = AnchorProps;
export const Anchor: FC<Props> = ({ children, ...props }) => {
  const { isEnabled } = useContext(ODLExperimentContext);
  if (!isEnabled) {
    return <CircuitAnchor {...props}>{children}</CircuitAnchor>;
  }
  return <BlackLink {...props}>{children}</BlackLink>;
};
