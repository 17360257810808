import * as handlers from './handlers';

import { tracker } from 'shared/services/tracker';
import { EVENTS } from 'shared/services/tracker/types';

const {
  SIGNUP,
  LOGIN,
  PURCHASE,
  GO_TO_ACTIVATION,
  GO_TO_CHECKOUT,
  ADDRESS_SUGGESTION_SELECTED,
  SKIP_SHOP,
  MORE_INFO,
  PROCEED_AS_REGISTERED_USER,
  GO_TO_PAYMENT,
} = EVENTS;

tracker.listen(SIGNUP, handlers.signupHandler);
tracker.listen(LOGIN, handlers.loginHandler);
tracker.listen(PURCHASE, handlers.purchaseHandler);
tracker.listen(GO_TO_ACTIVATION, handlers.goToActivationHandler);
tracker.listen(GO_TO_CHECKOUT, handlers.goToCheckoutHandler);
tracker.listen(PROCEED_AS_REGISTERED_USER, handlers.proceedAsRegisteredUser);
tracker.listen(
  ADDRESS_SUGGESTION_SELECTED,
  handlers.addessSuggestionSelectedHandler,
);
tracker.listen(SKIP_SHOP, handlers.skipShopHandler);
tracker.listen(MORE_INFO, handlers.moreInfoHandler);
tracker.listen(GO_TO_PAYMENT, handlers.goToPaymentHandler);
