import { Order } from '@commercelayer/sdk';

import { IPaymentMethod } from 'shared/infra/commerceLayer/types';

/**
 * ActionType types
 */
export enum OrderTypes {
  LOAD_ORDER_REQUEST = '@order/LOAD_ORDER_REQUEST',
  LOAD_ORDER_SUCCESS = '@order/LOAD_ORDER_SUCCESS',
  LOAD_ORDER_FAILURE = '@order/LOAD_ORDER_FAILURE',

  LOAD_PRODUCTS = '@order/LOAD_PRODUCTS',
  ADD_PRODUCT_REQUEST = '@order/ADD_PRODUCT_REQUEST',
  ADD_PRODUCT_SUCCESS = '@order/ADD_PRODUCT_SUCCCES',
  ADD_PRODUCT_FAILURE = '@order/ADD_PRODUCT_FAILURE',

  ADD_SINGLE_PRODUCT_REQUEST = '@order/ADD_SINGLE_PRODUCT_REQUEST',
  ADD_SINGLE_PRODUCT_SUCCESS = '@order/ADD_SINGLE_PRODUCT_SUCCESS',
  ADD_SINGLE_PRODUCT_FAILURE = '@order/ADD_SINGLE_PRODUCT_FAILURE',

  PLACE_ORDER_REQUEST = '@order/PLACE_ORDER_REQUEST',
  PLACE_ORDER_SUCCESS = '@order/PLACE_ORDER_SUCCESS',
  PLACE_ORDER_FAILURE = '@order/PLACE_ORDER_FAILURE',

  CLONE_ORDER_REQUEST = '@order/CLONE_ORDER_REQUEST',
  CLONE_ORDER_SUCCESS = '@order/CLONE_ORDER_SUCCESS',
  CLONE_ORDER_FAILURE = '@order/CLONE_ORDER_FAILURE',

  APPLY_COUPON_REQUEST = '@order/APPLY_COUPON_REQUEST',
  APPLY_COUPON_SUCCESS = '@order/APPLY_COUPON_SUCCESS',
  APPLY_COUPON_FAILURE = '@order/APPLY_COUPON_FAILURE',
  RESET_COUPON_REQUEST = '@order/RESET_COUPON_REQUEST',

  CHANGE_PRODUCT_QUANTITY = '@order/CHANGE_PRODUCT_QUANTITY',
  CHANGE_PRODUCT_QUANTITY_SUCCESS = '@order/CHANGE_PRODUCT_QUANTITY_SUCCESS',
  CHANGE_PRODUCT_QUANTITY_FAILURE = '@order/CHANGE_PRODUCT_QUANTITY_FAILURE',

  REMOVE_PRODUCT = '@order/REMOVE_PRODUCT',
  REMOVE_PRODUCT_SUCCESS = '@order/REMOVE_PRODUCT_SUCCESS',
  REMOVE_PRODUCT_FAILURE = '@order/REMOVE_PRODUCT_FAILURE',

  UPDATE_NOTIFICATION = '@order/UPDATE_NOTIFICATION',

  RESET_ORDER = '@order/RESET_ORDER',

  PROPOSE_BUSINESS_ACCOUNT = '@order/PROPOSE_BUSINESS_ACCOUNT',
  SELECT_BUSINESS_ACCOUNT = '@order/SELECT_BUSINESS_ACCOUNT',
}

/**
 * Action types
 */

/* Actions to handle the Order */
export interface ILoadOrderAction {
  type: OrderTypes.LOAD_ORDER_REQUEST;
}
export interface ILoadOrderSuccessAction {
  type: OrderTypes.LOAD_ORDER_SUCCESS;
  payload: IOrderDetails;
}
export interface ILoadOrderFailureAction {
  type: OrderTypes.LOAD_ORDER_FAILURE;
  payload: { message: string };
}

/* Actions to handle the Products in the Order */
export interface ILoadProductsAction {
  type: OrderTypes.LOAD_PRODUCTS;
  payload: ICLayerProduct[];
}

export interface IAddProductActionPayload {
  contentfulId: string;
}
export interface IAddProductAction {
  type: OrderTypes.ADD_PRODUCT_REQUEST;
  payload: IAddProductActionPayload;
}

export interface IProposeBusinessAccountAction {
  type: OrderTypes.PROPOSE_BUSINESS_ACCOUNT;
  payload: { value: boolean };
}

export interface ISelectBusinessAccountAction {
  type: OrderTypes.SELECT_BUSINESS_ACCOUNT;
  payload: { value: boolean };
}

export interface IAddProductSuccessAction {
  type: OrderTypes.ADD_PRODUCT_SUCCESS;
  payload: { message: string };
}

export interface IAddProductFailureAction {
  type: OrderTypes.ADD_PRODUCT_FAILURE;
  payload: { message: string };
}

export interface IPlaceOrderAction {
  type: OrderTypes.PLACE_ORDER_REQUEST;
  payload: IPlaceOrderPayload;
}

export interface IPlaceOrderSuccessAction {
  type: OrderTypes.PLACE_ORDER_SUCCESS;
  payload: { order: IOrderDetails };
}

export interface IPlaceOrderFailureAction {
  type: OrderTypes.PLACE_ORDER_FAILURE;
  payload: { message: string };
}

export interface ICloneOrderAction {
  type: OrderTypes.CLONE_ORDER_REQUEST;
  payload: ICloneOrderPayload;
}

export interface ICloneOrderSuccessAction {
  type: OrderTypes.CLONE_ORDER_SUCCESS;
}

export interface ICloneOrderFailuteAction {
  type: OrderTypes.CLONE_ORDER_FAILURE;
  payload: { message: string };
}

export interface IApplyCouponAction {
  type: OrderTypes.APPLY_COUPON_REQUEST;
  payload: { couponCode: string; updateCouponLimits?: boolean };
}

export interface IApplyCouponSuccessAction {
  type: OrderTypes.APPLY_COUPON_SUCCESS;
  payload: { order: IOrderDetails };
}

export interface IApplyCouponFailureAction {
  type: OrderTypes.APPLY_COUPON_FAILURE;
  payload: { errorMessage: string };
}

export interface IResetCouponAction {
  type: OrderTypes.RESET_COUPON_REQUEST;
}

export interface IChangeProductQuantityAction {
  type: OrderTypes.CHANGE_PRODUCT_QUANTITY;
  payload: { lineItemId: string; quantity: number; contentfulId: string };
}

export interface IChangeProductQuantitySuccessAction {
  type: OrderTypes.CHANGE_PRODUCT_QUANTITY_SUCCESS;
  payload: { lineItemId: string; quantity: number };
}

export interface IChangeProductQuantityFailureAction {
  type: OrderTypes.CHANGE_PRODUCT_QUANTITY_FAILURE;
  payload: { lineItemId: string; quantity: number };
}

export interface IRemoveProductAction {
  type: OrderTypes.REMOVE_PRODUCT;
  payload: { contentfulId: string };
}

export interface IRemoveProductSuccessAction {
  type: OrderTypes.REMOVE_PRODUCT_SUCCESS;
  payload: { lineItemId: string };
}

export interface IRemoveProductFailureAction {
  type: OrderTypes.REMOVE_PRODUCT_FAILURE;
  payload: { lineItemId: string };
}

export interface IUpdateNotificationAction {
  type: OrderTypes.UPDATE_NOTIFICATION;
  payload: {
    type: IOrderState['operationStatus']['type'];
    showNotification: boolean;
  };
}

export interface IResetOrderAction {
  type: OrderTypes.RESET_ORDER;
}

export type OrderActions =
  | ILoadOrderAction
  | ILoadOrderSuccessAction
  | ILoadOrderFailureAction
  | ILoadProductsAction
  | IAddProductAction
  | IAddProductSuccessAction
  | IAddProductFailureAction
  | IProposeBusinessAccountAction
  | ISelectBusinessAccountAction
  | IPlaceOrderAction
  | IPlaceOrderSuccessAction
  | IPlaceOrderFailureAction
  | ICloneOrderAction
  | ICloneOrderSuccessAction
  | ICloneOrderFailuteAction
  | IApplyCouponAction
  | IApplyCouponSuccessAction
  | IApplyCouponFailureAction
  | IResetCouponAction
  | IChangeProductQuantityAction
  | IChangeProductQuantitySuccessAction
  | IChangeProductQuantityFailureAction
  | IRemoveProductAction
  | IRemoveProductSuccessAction
  | IRemoveProductFailureAction
  | IUpdateNotificationAction
  | IResetOrderAction;

/**
 * State types
 */

export enum OrderStatuses {
  draft = 'draft',
  pending = 'pending',
  placed = 'placed',
  approved = 'approved',
  cancelled = 'cancelled',
}

export interface IOrderDetails {
  id: string;
  number: number;
  status: OrderStatuses;
  guest: boolean;
  formattedTotalAmount: string;
  /**
   * `formattedSubtotalAmount` contains the order price, without taxes or delivery rates.
   */
  formattedSubtotalAmount: string;
  /**
   * `formattedTotalTaxAmount` represents the value for the VAT price to be
   * charged during the checkout.
   */
  formattedTotalTaxAmount: string;
  /**
   * `taxRate` represents the value for the VAT rate to be
   * calculated for the user.
   */
  formattedTotalAmountWithTaxes: string;
  formattedDiscountAmount?: string;
  discountAmountCents?: number;
  couponCode?: string;
  languageCode?: string;
  taxRate: number;
  totalAmountFloat: number;
  totalAmountWithTaxesCents: number;
  totalAmountWithTaxesFloat: number;
  currencyCode: string;
  metadata: Record<string, unknown>;
  customerEmail: string;
}

export type IClayerOrder = Order & { status: OrderStatuses };
export interface ICLayerProduct {
  id: string;
  skuCode: string;
  /**
   * formattedUnitAmount: Full price, without discounts
   * */
  formattedUnitAmount: string;
  /**
   * formattedTotalAmount: Price with discounts applied
   * */
  formattedTotalAmount: string;
  /**
   * discountCents: applied discount amount in monetary cents.
   * */
  discountCents: number;
  /**
   * discountRate: the rate of the discountCents over
   * the unitAmount of the product.
   * */
  discountRate: number;
  quantity: number;
  reference?: string;
  unitAmountFloat: number;
  amountFloat: number;
}

export interface IOrderCoupon {
  couponCode: string;
  loading: boolean;
  error: boolean;
}

export interface IOrderState {
  readonly orderDetails: IOrderDetails;
  readonly products: ICLayerProduct[];
  readonly operationStatus: {
    type?:
      | 'addProduct'
      | 'removeProduct'
      | 'changeProductQuantity'
      | 'removeOutOfStockProduct';
    loading: boolean;
    productReference: string;
    showNotification: boolean;
  };
  readonly loading: boolean;
  readonly error: boolean;
  readonly coupon: IOrderCoupon;
  readonly isBusinessAccountProposed: boolean;
  readonly isBusinessAccountSelected: boolean;
}

export interface IPlaceOrderPayload {
  orderId: string;
  locale: string;
}

export interface ICloneOrderPayload {
  paymentMethod: IPaymentMethod;
  errorMessage?: string;
  errorCode?: string;
  country?: string;
  loadLocalOrderFn: () => Promise<void>;
}
