import { IExperiment } from './types';

export const FEATURE_TOGGLE_ENABLED_VALUE = 'true';
export const NEW_SHOP_ROLLOUT_VARIATION = 'new_shop';
export const NEW_SHOP_VS_OLD_SHOP_EXPERIMENTS = [
  '_shop__v2______eu_first_bucket_old_shop_vs__new_shop',
  '_shop__v2_eu______it_old_shop_vs_new_shop',
  '_shop__v2______eu_de_nl_be_ch_old_shop_vs_new_shop',
  '_shop___canary__v2______eu_second_bucket_old_shop_vs__new_shop',
  '_shop__v2_br______old_shop_or_new_shop',
];

export const PLATFORM = 'storefront';

export const WEBSITE_EXPERIMENTS_COOKIE_NAME = 'optimizely_experiments';
export const EXPERIMENT_USER_COOKIE_NAME = 'optimizely_experiment_user';
export const EXPERIMENT_PREVIEW_PARAM = 'optimizelyExperiment';
export const SHARED_IFRAME_STORAGE_URL =
  'https://static.sumup.com/shared-iframe-storage/index.html';

export const EXPERIMENT_COOKIES_MAX_AGE = 365;
export const IN_APP_PARAM = 'in_app';

export const CLIENT_ENGINES = {
  VERCEL_EDGE: 'javascript-sdk/vercel-edge',
};

export const SUMUP_COOKIE_DOMAIN = getSumupCookieDomain();

function getSumupCookieDomain() {
  const environment = process.env.ENVIRONMENT || 'preview';
  const domainFromENV = process.env.NEXT_PUBLIC_SUMUP_COOKIE_DOMAIN;

  if (domainFromENV) {
    return domainFromENV;
  }

  if (environment === 'production') {
    return '.sumup.com';
  }

  if (environment === 'preview') {
    return '.sumup-vercel.app';
  }

  return '';
}

export const SUPPORTED_DYNAMIC_ROUTES = ['/product-selection/[productName]'];

export const CONTROL = 'control';
export const VARIATION = 'variation';

export const PS_PRODUCT_GRID: IExperiment = {
  id: '_shop___cl_us__product_selection_grid',

  activeRoutes: ['/product-selection'],
  type: 'component',
  variations: [
    {
      id: CONTROL,
    },
    {
      id: VARIATION,
    },
  ],
  metadata: {
    owner: 'explore',
  },
};

export const CORE_EU_CTA_TO_SSO: IExperiment = {
  id: '_shop__cta_to_sso___contextualized_signup_-_core_eu',
  activeRoutes: ['/product-selection'],
  type: 'component',
  variations: [
    {
      id: CONTROL,
    },
    {
      id: VARIATION,
    },
  ],
  metadata: {
    owner: 'explore',
  },
};

export const SHOW_VAT_AMOUNT_EXPERIMENT: IExperiment = {
  id: '_shop__show_vat_amount',
  type: 'component',
  variations: [
    {
      id: CONTROL,
    },
    {
      id: VARIATION,
    },
  ],
  websiteSync: true,
  metadata: {
    owner: 'purchase',
    experiment:
      'https://app.optimizely.com/v2/projects/18423480104/experiments/24192120046/variations',
    docs: '',
  },
};

export const BUSINESS_ACCOUNT_BUNDLE: IExperiment = {
  id: '_shop_business_account_bundle_gb',
  activeRoutes: ['/product-selection', '/product-selection/[productName]'],
  type: 'component',
  variations: [
    {
      id: CONTROL,
    },
    {
      id: VARIATION,
    },
  ],
  websiteSync: true,
  metadata: {
    owner: 'explore',
    experiment:
      'https://app.optimizely.com/v2/projects/18423480104/experiments/23361271746/variations',
    docs: 'https://sumupteam.atlassian.net/wiki/spaces/DEV/pages/22102474753/OKRs+Q1+2023+Explore+squad#KR1.-Test-the-Bank-+-CR-bundle-with-selection-in-the-UK-website-to-checkout-funnel-with-100%-uplift-on-bank-MAU-(from-20-to-40%-Bank-conversion)',
  },
};

export const ODL_EXPERIMENT: IExperiment = {
  id: '_website_shop__odl',
  type: 'component',
  variations: [
    {
      id: CONTROL,
    },
    {
      id: VARIATION,
    },
  ],
  metadata: {
    owner: 'explore',
    experiment:
      'https://app.optimizely.com/v2/projects/18423480104/experiments/23586430251/variations',
    docs: 'https://sumupteam.atlassian.net/wiki/spaces/DEV/pages/22102474753/OKRs+Q1+2023+Explore+squad#KR1.-Infuse-ODL-into-product-selection:-Black-&-White-CTA--A/B-Test-in-US,-IE,-NL,-BR-to-validate-the-direction-(cond.:-no-conversion-drops)-[Jira-Ticket]',
  },
};

export const THANK_YOU_PAGE_REVAMP: IExperiment = {
  id: '_shop__thank_you_page_revamp',
  activeRoutes: ['/thank-you'],
  type: 'component',
  variations: [
    {
      id: CONTROL,
    },
    {
      id: VARIATION,
    },
  ],
  metadata: {
    owner: 'explore',
    experiment:
      'https://app.optimizely.com/v2/projects/18423480104/experiments/22606981503/variations',
    docs: 'https://sumupteam.atlassian.net/wiki/spaces/DEV/pages/22064825105/WIP+11+22+Growth+experiment+-+Business+Account+on+TYP?NO_SSR=1',
  },
};

export const RUNNING_EXPERIMENTS: IExperiment[] = [
  PS_PRODUCT_GRID,
  CORE_EU_CTA_TO_SSO,
  THANK_YOU_PAGE_REVAMP,
  BUSINESS_ACCOUNT_BUNDLE,
  ODL_EXPERIMENT,
  SHOW_VAT_AMOUNT_EXPERIMENT,
];
export const ADDRESS_AUTOCOMPLETE_TOGGLE_KEY = '_shop__address_autocomplete';
export const PS_CTA_TO_CONTEXTUALIZED_SSO =
  '_shop__br__product_selection_cta_to_sso';
export const TRANSPARENT_FEE = '_shop__transparent_fees';

export const RUNNING_FEATURE_TOGGLES = [
  PS_CTA_TO_CONTEXTUALIZED_SSO,
  ADDRESS_AUTOCOMPLETE_TOGGLE_KEY,
  TRANSPARENT_FEE,
];
