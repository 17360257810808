import Cookie from 'js-cookie';

export * from './constants';

interface IStorageOptions {
  expires?: number | Date;
}

export const setItem = (
  name: string,
  value: string,
  options: IStorageOptions,
): void => {
  Cookie.set(name, value, options);
};

export const getItem = (name: string, defaultValue = ''): string => {
  const item = Cookie.get(name);

  if (item) {
    return item;
  }

  return defaultValue;
};

export const removeItem = (name: string): void => {
  Cookie.remove(name);
};
