// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import { init, setUser } from '@sentry/nextjs';

import { Integrations as TracingIntegrations } from '@sentry/tracing';
import { CaptureConsole as CaptureConsoleIntegration } from '@sentry/integrations';

import { cookieConsentEvents } from './utils/scripts/cookieConsent/cookieConsent';

const DEFAULT_SAMPLE_RATE = 0.5;

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;
const tracesSampleRate =
  parseFloat(process.env.NEXT_PUBLIC_SENTRY_TRACE_SAMPLE_RATE) ||
  DEFAULT_SAMPLE_RATE;

const SENTRY_ENVIRONMENT =
  process.env.ENVIRONMENT || process.env.NEXT_PUBLIC_ENVIRONMENT;

init({
  dsn: SENTRY_DSN,
  environment: SENTRY_ENVIRONMENT,
  // Adjust this value in production, or use tracesSampler for greater control
  integrations: [
    new TracingIntegrations.BrowserTracing(),
    new CaptureConsoleIntegration({
      levels: ['error'],
    }),
  ],
  tracesSampleRate,
});

const SENTRY_USER_ID = 'sentry_user_id';

/*
 * Sentry cookies fall under the 'performance' category.
 * Setting the user to null anonymizes any subsequent data
 * if a user changes their preferences.
 */
function handleConsent({ categories }) {
  if (categories.includes === 'PERFORMANCE') {
    const localStorageId = localStorage.getItem(SENTRY_USER_ID);
    const id = localStorageId || uuid();

    if (!localStorageId) {
      localStorage.setItem(SENTRY_USER_ID, id);
    }

    setUser({ id });
  } else {
    setUser(null);
  }
}

cookieConsentEvents.on('init', handleConsent);
cookieConsentEvents.on('change', handleConsent);
