/* eslint-disable jsx-a11y/iframe-has-title */
import { Fragment } from 'react';
import Script from 'next/script';

const GTM_ID = 'GTM-ZC88';

export const shouldLoadGtmScript = () =>
  process.env.NEXT_PUBLIC_ENVIRONMENT === 'production' ||
  process.env.NEXT_PUBLIC_VERCEL_PREVIEW_ENV_STAGING === 'true';

export function GtmConsentScripts(): JSX.Element {
  /* Set the default consent based on the OneTrust cookie. */
  return (
    <Script
      id="gtm-helpers"
      strategy="afterInteractive"
      dangerouslySetInnerHTML={{
        __html:
          // The backslash is valid, applying Prettier's suggestion would break it.
          /* eslint-disable-next-line no-useless-escape */ /* prettier-ignore */
          `
         function getCategories() {
           try {
             var e = decodeURIComponent(document.cookie);
             return /groups=([C\\d\\:\\,]+)/
               .exec(e)[1]
               .split(",")
               .reduce(function (e, t) {
                 var a = t.split(":");
                 return (e[a[0]] = a[1]), e;
               }, {});
           } catch (e) {
             return { C0001: "1" };
           }
         }

         function setDefaultGTMConsent() {
           var categories = getCategories();
           if (typeof window.OnetrustActiveGroups !== 'undefined') {
             categories = window.OnetrustActiveGroups.split(",")
               .filter(Boolean)
               .reduce((acc, v) => {
                 acc[v] = "1";
                 return acc;
               }, {});
           }
           var analyticsStorageGranted = "1" === categories.C0002;
           var adStorageGranted = "1" === categories.C0004;
           gtag("consent", "default", {
             analytics_storage: analyticsStorageGranted ? "granted" : "denied",
             ad_storage: adStorageGranted ? "granted" : "denied",
           });
           if (!adStorageGranted) {
             gtag("set", "ads_data_redaction", true);
           }
         }
         setDefaultGTMConsent();
         `,
      }}
    />
  );
}

export function GtmGtagScript(): JSX.Element {
  /* Set the default consent based on the OneTrust cookie. */
  return (
    <Script
      id="gtm-gtag"
      strategy="afterInteractive"
      dangerouslySetInnerHTML={{
        __html:
          // The backslash is valid, applying Prettier's suggestion would break it.
          /* eslint-disable-next-line no-useless-escape */ /* prettier-ignore */
          `
          function gtag() {
            dataLayer.push(arguments);
          }

          window.dataLayer = window.dataLayer || [];
         `,
      }}
    />
  );
}

export function GtmScript(): JSX.Element {
  return (
    <Fragment>
      <Script
        strategy="afterInteractive"
        id="gtm-script"
        dangerouslySetInnerHTML={{
          __html: `
          (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          '//www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','${GTM_ID}');
        `,
        }}
      />

      <noscript>
        <iframe
          src={`//www.googletagmanager.com/ns.html?id=${GTM_ID}`}
          height="0"
          width="0"
          style={{ display: 'none', visibility: 'hidden' }}
        />
      </noscript>
    </Fragment>
  );
}
