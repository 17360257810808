import * as handlers from './handlers';

import { tracker } from 'shared/services/tracker';
import { EVENTS } from 'shared/services/tracker/types';

const {
  PAGEVIEW,
  ADD_PRODUCT,
  SIGNUP,
  LOGIN,
  PURCHASE,
  PURCHASE_FAILURE,
  GO_TO_ACTIVATION,
  GO_TO_CHECKOUT,
  MORE_INFO,
  SKIP_SHOP,
  GO_TO_PAYMENT,
  INPUT_ERROR,
  VOUCHER_APPLIED,
  GO_TO_WEBSITE,
  PROCEED_AS_REGISTERED_USER,
  BA_LEARN_MORE_OPEN,
  BA_LEARN_MORE_CLOSE,
  BA_SELECTED,
  BA_UNSELECTED,
  BA_CONDITIONS_MODAL_OPEN,
  BA_CONDITIONS_MODAL_CLOSE,
} = EVENTS;

tracker.listen(PAGEVIEW, handlers.pageviewHandler);
tracker.listen(ADD_PRODUCT, handlers.addProductHandler);
tracker.listen(SIGNUP, handlers.signupHandler);
tracker.listen(LOGIN, handlers.loginHandler);
tracker.listen(PURCHASE, handlers.purchaseCheckoutHandler);
tracker.listen(PURCHASE, handlers.purchaseCheckoutSuccessHandler);
tracker.listen(PURCHASE_FAILURE, handlers.purchaseFailureHandler);
tracker.listen(GO_TO_ACTIVATION, handlers.goToActivationHandler);
tracker.listen(GO_TO_CHECKOUT, handlers.goToCheckoutHandler);
tracker.listen(
  PROCEED_AS_REGISTERED_USER,
  handlers.proceedAsRegisteredUserHandler,
);
tracker.listen(MORE_INFO, handlers.moreInfoHandler);
tracker.listen(SKIP_SHOP, handlers.skipShopHandler);
tracker.listen(GO_TO_PAYMENT, handlers.goToPaymentHandler);
tracker.listen(INPUT_ERROR, handlers.inputErrorHandler);
tracker.listen(VOUCHER_APPLIED, handlers.voucherAppliedHandler);
tracker.listen(GO_TO_WEBSITE, handlers.goToWebsiteHandler);

// Business Account experiment tracking
tracker.listen(
  BA_LEARN_MORE_OPEN,
  handlers.businessAccountLearnMoreOpenHandler,
);
tracker.listen(
  BA_LEARN_MORE_CLOSE,
  handlers.businessAccountLearnMoreCloseHandler,
);
tracker.listen(BA_SELECTED, handlers.businessAccountSelectedHandler);
tracker.listen(BA_UNSELECTED, handlers.businessAccountUnselectedHandler);
tracker.listen(
  BA_CONDITIONS_MODAL_OPEN,
  handlers.businessAccountConditionsModalOpenHandler,
);
tracker.listen(
  BA_CONDITIONS_MODAL_CLOSE,
  handlers.businessAccountConditionsModalCloseHandler,
);
