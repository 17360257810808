/* eslint-disable @typescript-eslint/naming-convention, no-underscore-dangle */
import CommerceLayer, { CommerceLayerClient } from '@commercelayer/sdk';
import { captureException } from '@sentry/nextjs';

import { salesChannelAuth } from '../commerceLayer/auth';

import {
  dispatchEcomClientInit,
  dispatchEcomClientInitFailure,
} from 'shared/services/tracker';
import isServer from 'shared/utils/is-server';

export const EXCEPTION = 'Initializing EcomClient failed!';

export const CLAYER_ORG = 'sumup';

interface IEcomClient {
  init: (
    market: string,
    isRefresh?: boolean,
    clientId?: string,
  ) => Promise<CommerceLayerClient>;
  setClient: (accessToken: string) => CommerceLayerClient;
  getClient: () => Promise<CommerceLayerClient>;
  getAccessToken: () => Promise<string>;
  getIsTokenExpired: () => boolean;
  refetchSalesChannelAuthToken: () => Promise<void>;
}

const getEcomClient = (): IEcomClient => {
  let ecom: CommerceLayerClient;

  let _accessToken: string;
  let _market: string;
  let _isTokenExpired: () => boolean;

  let handleReady: (value: unknown) => void;
  let handleInitFailed: (e: unknown) => void;

  const isReadyPromise = new Promise((resolve, reject) => {
    handleReady = resolve;
    handleInitFailed = reject;
  });

  const setClient = (token: string) => {
    _accessToken = token;
    ecom = CommerceLayer({
      accessToken: _accessToken,
      organization: CLAYER_ORG,
    });
    return ecom;
  };

  const setMarket = (market: string) => {
    _market = market;
  };

  const setIsTokenExpired = (isTokenExpiredCallback: () => boolean) => {
    _isTokenExpired = isTokenExpiredCallback;
  };

  const getMarket = () => _market;
  const getIsTokenExpired = () => _isTokenExpired();

  // Market reference from contentful refers to the market defined in CommerceLayer
  // https://sumup.commercelayer.io/admin/settings/markets
  const init = async (market: string, isRefresh = false) => {
    try {
      const { accessToken, isTokenExpired } = await salesChannelAuth(
        market,
        process.env.NEXT_PUBLIC_COMMERCE_LAYER_CLIENT_ID,
      );
      setIsTokenExpired(isTokenExpired);
      setMarket(market);
      setClient(accessToken);
      handleReady(accessToken);
      dispatchEcomClientInit({ market, isRefresh: isRefresh.toString() });
      return ecom;
    } catch (e) {
      handleInitFailed(e);
      captureException(e, {
        extra: {
          description: EXCEPTION,
          isRefresh,
        },
        tags: {
          scope: market,
          context: isServer,
        },
      });
      dispatchEcomClientInitFailure({
        market,
        isRefresh: isRefresh.toString(),
      });
      return null;
    }
  };

  const refetchSalesChannelAuthToken = async () => {
    await init(getMarket(), true);
  };

  const getClient = async () => {
    await isReadyPromise;

    if (_isTokenExpired && getIsTokenExpired()) {
      await refetchSalesChannelAuthToken();
    }

    return ecom;
  };

  const getAccessToken = async () => {
    await isReadyPromise;

    if (_isTokenExpired && getIsTokenExpired()) {
      await refetchSalesChannelAuthToken();
    }

    return _accessToken;
  };

  return {
    init,
    setClient,
    getClient,
    getAccessToken,
    getIsTokenExpired,
    refetchSalesChannelAuthToken,
  };
};

const EcomClient = getEcomClient();

export default EcomClient;
