import Pythia, { getBrowserTags } from '@sumup/pythia';

import isServer from 'shared/utils/is-server';

const PYTHIA_API_KEY = 'AB7D56F7-8064-4DC3-B2AF-6AB8961EAB85';
const PYTHIA_APP_NAME = 'storefrontweb';
const DEVELOPMENT_ENVIRONMENT = 'development';
const PRODUCTION_ENVIRONMENT = 'production';

function createMetricsClient() {
  if (isServer) {
    // eslint-disable-next-line no-console
    return { send: () => console.warn('Pythia SDK cannot be used on server') };
  }

  const env = process.env.NEXT_PUBLIC_ENVIRONMENT || DEVELOPMENT_ENVIRONMENT;

  if (env === DEVELOPMENT_ENVIRONMENT) {
    return {
      // eslint-disable-next-line no-console
      send: (event: unknown) => console.info('Pythia metrics event:', event),
    };
  }

  if (env === PRODUCTION_ENVIRONMENT) {
    const browserTags = getBrowserTags();

    return new Pythia({
      env,
      defaultTags: { appName: PYTHIA_APP_NAME, ...browserTags },
      apiToken: PYTHIA_API_KEY,
    });
  }

  return { send: () => {} };
}

export const pythia = createMetricsClient();
