import { useCallback, useState, FC, FormEvent } from 'react';
import { Select, Headline, spacing, Hamburger, Badge } from '@sumup/circuit-ui';
import { useRouter } from 'next/router';
import styled from '@emotion/styled';

import { LOCALES } from 'tools/locales';
import { ShopExperience } from 'shared/constants/ShopExperience';
import { useShopExperience } from 'shared/context';
import { Button } from 'src/experiments/odl/components/Button';

const localeOptions = (LOCALES as string[]).map((isoLocale) => ({
  label: isoLocale,
  value: isoLocale,
}));

const shopOptions = [
  { label: ShopExperience.SIGNUP, value: ShopExperience.SIGNUP },
  { label: ShopExperience.DASHBOARD, value: ShopExperience.DASHBOARD },
];

const Wrapper = styled('div')(
  ({ theme }) => `
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    background: var(--cui-bg-normal);
    z-index: ${theme.zIndex.popover};
    padding: ${theme.spacings.mega} ${theme.spacings.mega} ${theme.spacings.mega} 70px;
    border-bottom: ${theme.borderWidth.kilo} solid var(--cui-border-normal);
    `,
);

const OptionsWrapper = styled('div')(
  ({ theme }) => `
    display: flex;
    flex-wrap: wrap;
    gap: ${theme.spacings.mega};
    `,
);
const StyledHamburger = styled(Hamburger)(
  ({ theme }) => `
   position: absolute;
   top: ${theme.spacings.bit};
   left: ${theme.spacings.bit};
   z-index: ${theme.zIndex.tooltip};
   border: ${theme.borderWidth.kilo} solid var(--cui-border-normal);
    `,
);
const StyledBadge = styled(Badge)(
  ({ theme }) => `
    position: absolute;
    top: ${theme.spacings.mega};
    left: ${theme.spacings.zetta};
    z-index: ${theme.zIndex.absolute};
    border: ${theme.borderWidth.kilo} solid var(--cui-border-normal);
  `,
);

export const PreviewControls: FC = () => {
  const { locale: selectedLocale, pathname, push, query } = useRouter();
  const experience = useShopExperience();
  const [isVisible, setVisible] = useState(false);

  const handleLocaleChange = useCallback(
    (event: FormEvent<HTMLSelectElement>) => {
      const newLocale = event.currentTarget.value;
      push(
        {
          pathname,
          query,
        },
        undefined,
        { locale: newLocale },
      );
    },
    [pathname, push, query],
  );

  const handleShopChange = useCallback(
    (event: React.FormEvent<HTMLSelectElement>) => {
      const newShopExperience = event.currentTarget.value;
      if (newShopExperience === ShopExperience.DASHBOARD) {
        push({
          pathname: `/dashboard${pathname}`,
          query: {
            ...query,
            source: ShopExperience.DASHBOARD,
          },
        });
      } else {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { source, ...queryWithoutSource } = query;

        push({
          pathname: pathname
            .replace('/dashboard/', '/')
            .replace('/[source]', ''),
          query: queryWithoutSource,
        });
      }
    },
    [pathname, push, query],
  );

  return (
    <>
      <StyledHamburger
        activeLabel="Close menu"
        inactiveLabel="Open menu"
        isActive={isVisible}
        onClick={function noRefCheck() {
          setVisible((visible) => !visible);
        }}
      />
      <StyledBadge aria-label="Preview" variant="neutral">
        Preview
      </StyledBadge>
      {isVisible && (
        <Wrapper>
          <>
            <Headline
              size="three"
              as="h6"
              css={spacing({ bottom: 'kilo', top: 'byte' })}
            >
              Manage Preview Options
            </Headline>
            <OptionsWrapper>
              <Select
                css={spacing({
                  bottom: 'mega',
                })}
                label="Change locale"
                options={localeOptions}
                value={selectedLocale}
                onChange={handleLocaleChange}
              />

              <Select
                css={spacing({
                  bottom: 'mega',
                })}
                label="Change shop (signup / dashboard)"
                options={shopOptions}
                value={experience}
                onChange={handleShopChange}
              />
            </OptionsWrapper>

            <Button
              type="button"
              onClick={() => {
                window.location.href = '/api/preview/exit';
              }}
              variant="primary"
            >
              Exit preview mode
            </Button>
          </>
        </Wrapper>
      )}
    </>
  );
};
