import {
  createStore,
  applyMiddleware,
  compose,
  Store,
  StoreEnhancer,
} from 'redux';
import { useSelector, TypedUseSelectorHook } from 'react-redux';
import createSagaMiddleware from 'redux-saga';
import { createWrapper } from 'next-redux-wrapper';

import { rootSagas } from 'shared/store/rootSagas';
import { rootReducer } from 'shared/store/rootReducer';
import { IOrderState } from 'shared/store/order/types';
import { IAddressState } from 'shared/store/address/types';
import { ICatalogState } from 'shared/store/catalog/types';
import { PromotionsState } from 'shared/store/promotions/types';
import { IPaymentGlobalState } from 'shared/store/payment/reducers';

export interface IAppState {
  order: IOrderState;
  addresses: IAddressState;
  catalog: ICatalogState;
  promotions: PromotionsState;
  payment: IPaymentGlobalState;
}

const composeEnhancer = (
  middlewares: StoreEnhancer<Record<string, unknown>, IAppState>,
): StoreEnhancer<Record<string, unknown>, IAppState> => {
  const globalDevTools: typeof compose = global[
    // eslint-disable-next-line @typescript-eslint/dot-notation
    '__REDUX_DEVTOOLS_EXTENSION_COMPOSE__'
  ] as typeof compose;

  if (process.env.NODE_ENV !== 'production' && globalDevTools) {
    return globalDevTools(middlewares);
  }
  return compose(middlewares);
};

const sagaMiddleware = createSagaMiddleware();

export const configStore = (): Store<IAppState> => {
  const store = createStore(
    rootReducer,
    composeEnhancer(applyMiddleware(sagaMiddleware)),
  );

  sagaMiddleware.run(rootSagas);

  return store;
};

/**
 * The `useTypedSelector` was created to avoid repeating
 * the IAppState type declaration throughout every place
 * the react-redux's `useSelector` is being used.
 */
export const useTypedSelector: TypedUseSelectorHook<IAppState> = useSelector;

const nextReduxWrapper = createWrapper(configStore, { debug: false });

export const withNextRedux = nextReduxWrapper.withRedux;
