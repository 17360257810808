import { FC, useState, useEffect, useCallback, ReactNode } from 'react';
import { useRouter } from 'next/router';
import {
  OptimizelyProvider as OptimizelyFSProvider,
  enums,
  createInstance,
} from '@optimizely/react-sdk';

import {
  NEW_SHOP_VS_OLD_SHOP_EXPERIMENTS,
  NEW_SHOP_ROLLOUT_VARIATION,
} from './constants';
import { useOptimizelyUserTimers } from './hooks';

import {
  optimizely,
  isOptimizelyReady,
  getUserId,
  getAttributes,
  createSharedIframeStorage,
  onActivate,
  forceOptimizelyVariation,
} from './index';

import isServer from 'shared/utils/is-server';
import { getCountryCodeFromISOLocale } from 'shared/i18n/helpers';

optimizely.notificationCenter.addNotificationListener(
  enums.NOTIFICATION_TYPES.ACTIVATE,
  onActivate,
);

type IUser = {
  id: string;
  attributes?: unknown;
};

export const OptimizelyProvider: FC<{ children?: ReactNode }> = ({
  children,
}) => {
  const [user, setUser] = useState<IUser>();
  const [isReady, setReady] = useState(false);
  const { locale, isPreview } = useRouter();
  const countryCode = getCountryCodeFromISOLocale(locale);

  useOptimizelyUserTimers();

  const getOptimizelyUser = useCallback(
    (): Promise<IUser> =>
      createSharedIframeStorage().then(() => ({
        id: getUserId(),
        attributes: getAttributes(countryCode),
      })),
    [countryCode],
  );

  /** Programmatically force an experiment activation on the shop
   * this is used only temporarily to activate the experiments attributed
   * on Cloudfront and enable experiment data to be sent to GA.
   */
  const forceUserRolloutVariation = useCallback(
    (variation: string): void => {
      NEW_SHOP_VS_OLD_SHOP_EXPERIMENTS.forEach((experiment) => {
        forceOptimizelyVariation(
          experiment,
          optimizely,
          user.id,
          user.attributes,
          variation,
        );
      });
    },
    [user?.attributes, user?.id],
  );

  useEffect(() => {
    getOptimizelyUser().then(setUser);
  }, [getOptimizelyUser]);

  useEffect(() => {
    isOptimizelyReady().then(({ success }) => setReady(success));
  }, []);

  useEffect(() => {
    if (user?.id && isReady) {
      forceUserRolloutVariation(NEW_SHOP_ROLLOUT_VARIATION);
    }
  }, [user, forceUserRolloutVariation, isReady]);

  return (
    <OptimizelyFSProvider
      optimizely={isPreview ? createInstance({}) : optimizely}
      user={!isServer && getOptimizelyUser()}
    >
      {children}
    </OptimizelyFSProvider>
  );
};
