import { Reducer } from 'redux';

import {
  ICatalogState,
  CatalogTypes,
  CatalogActions,
} from 'shared/store/catalog/types';

export const INITIAL_STATE: ICatalogState = {};

export const catalog: Reducer<ICatalogState> = (
  state = INITIAL_STATE,
  action: CatalogActions,
) => {
  switch (action.type) {
    case CatalogTypes.PROVIDE_CATALOG: {
      return { ...action.payload };
    }
    default: {
      return { ...state };
    }
  }
};
