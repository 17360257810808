import { ShopExperience } from 'shared/constants/ShopExperience';
import { RUNNING_EXPERIMENTS } from 'shared/services/optimizely/constants';
import { retrieveGlobalCatalog } from 'shared/services/OrderInformationService.globals';
import isServer from 'shared/utils/is-server';

/**
 * Get shop experience based on the pathname the application is accessed by
 * pathname === /dashboard/product-selection => shopExperience === DASHBOARD
 * pathname === /point-of-sale/product-selection => shopExperience === POINT_OF_SALE
 * we are defaulting to the signup shop experience
 * pathname === /product-selection => shopExperience === SIGNUP
 * @returns {ShopExperience}
 */
export const getShopExperienceFromSource = (url?: string): ShopExperience => {
  if (url) {
    return getShopExperienceFromUrl(url);
  }

  if (isServer) {
    return retrieveGlobalCatalog() || ShopExperience.SIGNUP;
  }

  return getShopExperienceFromUrl(window.location.pathname);
};

/**
 * @param {string} pathname - Link where to redirect
 * @returns string Final link considering the shop experience (dashboard | signup)
 */
export function getShopExperienceLink(pathname: string): string {
  if (pathname.charAt(0) !== '/') {
    throw new Error('Only relative pathname is accepted!');
  }

  const shopExperienceFromSource = getShopExperienceFromSource();
  return shopExperienceFromSource === ShopExperience.SIGNUP
    ? pathname
    : `/${shopExperienceFromSource}${pathname}`;
}

export const removeProductIdFromQuery = (
  query: Record<string, string | string[]>,
): Record<string, string | string[]> => {
  const queryCopy = { ...query };
  delete queryCopy['product-id'];
  return queryCopy;
};

export const cleanupQuery = (
  query: Record<string, string | string[]>,
): Record<string, string | string[]> => {
  const queryCopy = { ...query };
  RUNNING_EXPERIMENTS.map((experiment) => delete queryCopy[experiment.id]);
  return queryCopy;
};

function getShopExperienceFromUrl(url: string): ShopExperience {
  const dashboardShopPathParam = '/dashboard/';
  const postShopPathParam = '/point-of-sale/';

  const isDashboard = url.includes(dashboardShopPathParam);

  const isPOS = url.includes(postShopPathParam);

  if (isDashboard) {
    return ShopExperience.DASHBOARD;
  }

  if (isPOS) {
    return ShopExperience.POINT_OF_SALE;
  }

  return ShopExperience.SIGNUP;
}
