import { IPaymentActionTypes, IPaymentActions, IPaymentState } from './types';

type StoreManagementState = {
  loading: boolean;
  error: string;
};

export type IPaymentGlobalState = IPaymentState & StoreManagementState;

export const INITIAL_STATE: IPaymentGlobalState = {
  id: null,
  reference: null, // payment_method name
  checkoutId: null,
  paymentCode: null,
  documentDownloadUrl: null,
  loading: false,
  error: null,
};

const reducer = (
  state = INITIAL_STATE,
  action: IPaymentActions,
): IPaymentGlobalState => {
  switch (action.type) {
    case IPaymentActionTypes.ATTACH_PAYMENT_METHOD_REQUEST: {
      return {
        ...state,
        loading: true,
        error: null,
      };
    }

    case IPaymentActionTypes.SET_LOADING: {
      return {
        ...state,
        loading: action.payload.isLoading ?? true,
        error: null,
      };
    }

    case IPaymentActionTypes.ATTACH_PAYMENT_METHOD_FAILURE:
    case IPaymentActionTypes.SET_ERROR: {
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    }

    case IPaymentActionTypes.ATTACH_PAYMENT_METHOD_SUCCESS: {
      return {
        ...state,
        id: action.payload.id,
        reference: action.payload.reference,
        loading: false,
        error: null,
      };
    }

    case IPaymentActionTypes.ATTACH_CHECKOUT_ID: {
      return {
        ...state,
        checkoutId: action.payload.checkoutId,
      };
    }
    case IPaymentActionTypes.ATTACH_PAYMENT_CODE: {
      return {
        ...state,
        paymentCode: action.payload.code,
      };
    }
    case IPaymentActionTypes.ATTACH_DOWNLOAD_URL: {
      return {
        ...state,
        documentDownloadUrl: action.payload.documentDownloadUrl,
      };
    }
    default:
      return state;
  }
};

export default reducer;
