import { Coupon, Promotion as CLPromotion } from '@commercelayer/sdk';

import { ISupportedPromotion } from 'shared/infra/commerceLayer/promotions';

/**
 * Action types
 */
export enum PromotionsTypes {
  LOAD_PROMOTIONS_REQUEST = '@coupons/LOAD_PROMOTIONS_REQUEST',
  LOAD_PROMOTIONS_SUCCESS = '@coupons/LOAD_PROMOTIONS_SUCCESS',
  LOAD_PROMOTIONS_FAILURE = '@coupons/LOAD_PROMOTIONS_FAILURE',
  RESET_PROMOTIONS = '@coupons/RESET_PROMOTIONS',
}

export interface ILoadPromotionsPayload {
  coupons: string[];
}

export interface ILoadPromotionsAction {
  type: PromotionsTypes.LOAD_PROMOTIONS_REQUEST;
  payload: ILoadPromotionsPayload;
}
export interface ILoadPromotionsSuccessAction {
  type: PromotionsTypes.LOAD_PROMOTIONS_SUCCESS;
  payload: {
    details: Record<string, Promotion>;
  };
}
export interface ILoadPromotionsFailureAction {
  type: PromotionsTypes.LOAD_PROMOTIONS_FAILURE;
  payload: { message?: string };
}

export interface IResetPromotionsAction {
  type: PromotionsTypes.RESET_PROMOTIONS;
}

export type PromotionsActions =
  | ILoadPromotionsAction
  | ILoadPromotionsSuccessAction
  | ILoadPromotionsFailureAction
  | IResetPromotionsAction;

export type Promotion = {
  couponCode: Coupon['code'];
  name: CLPromotion['name'];
  fixedAmountCents: ISupportedPromotion['fixed_amount_cents'];
  fixedAmountFloat: ISupportedPromotion['fixed_amount_float'];
  formattedFixedAmount: ISupportedPromotion['formatted_fixed_amount'];
  type: CLPromotion['type'];
  purchaseLimit?: number;
};

export type PromotionsState = {
  readonly loading: boolean;
  readonly error: boolean;
  readonly details: Record<string, Promotion>;
};
