import { UrlObject } from 'url';

import { css } from '@emotion/react';
import { Anchor as CircuitAnchor, AnchorProps } from '@sumup/circuit-ui';
import styled from '@emotion/styled';
import NextLink, { LinkProps } from 'next/link';
import { FC, forwardRef, useContext } from 'react';

import { ODLExperimentContext } from '../context';

import { Anchor } from './Anchor';

const StyledLink = styled(Anchor)(
  () => css`
    text-decoration: none;
    color: inherit;
    &:hover,
    &:focus {
      text-decoration: underline;
    }
  `,
);

const StyledCircuitLink = styled(CircuitAnchor)(
  () => css`
    text-decoration: none;
    color: inherit;
    &:hover,
    &:focus {
      text-decoration: underline;
    }
  `,
);

const StyledExternalLink = styled(StyledLink)(
  () => css`
    font-weight: initial;
    text-decoration: underline;
  `,
);

const StyledCircuitExternalLink = styled(StyledCircuitLink)(
  ({ theme }) => css`
    color: ${theme.colors.b500};
    font-weight: initial;
    text-decoration: underline;
  `,
);

export interface IInternalLinkProps
  extends Pick<
      LinkProps,
      | 'href'
      | 'replace'
      | 'scroll'
      | 'shallow'
      | 'passHref'
      | 'prefetch'
      | 'locale'
    >,
    Omit<AnchorProps, 'href'> {
  href: string | UrlObject;
}

const LinkWrapper = forwardRef<
  HTMLButtonElement & HTMLAnchorElement,
  AnchorProps
>(({ children, ...props }, ref) => (
  <StyledLink {...props} ref={ref}>
    {children}
  </StyledLink>
));

const CircuitLinkWrapper = forwardRef<
  HTMLButtonElement & HTMLAnchorElement,
  AnchorProps
>(({ children, ...props }, ref) => (
  <StyledCircuitLink {...props} ref={ref}>
    {children}
  </StyledCircuitLink>
));

export const InternalLink: FC<IInternalLinkProps> = ({
  href,
  as = 'a',
  replace,
  scroll,
  shallow,
  passHref = true,
  prefetch,
  locale,
  children,
  ...props
}) => {
  const { isEnabled } = useContext(ODLExperimentContext);
  if (!isEnabled) {
    return (
      <NextLink
        href={href}
        replace={replace}
        scroll={scroll}
        shallow={shallow}
        passHref={passHref}
        prefetch={prefetch}
        locale={locale}
      >
        <CircuitLinkWrapper variant="highlight" {...props} as={as}>
          {children}
        </CircuitLinkWrapper>
      </NextLink>
    );
  }
  return (
    <NextLink
      href={href}
      replace={replace}
      scroll={scroll}
      shallow={shallow}
      passHref={passHref}
      prefetch={prefetch}
      locale={locale}
    >
      <LinkWrapper variant="highlight" {...props} as={as}>
        {children}
      </LinkWrapper>
    </NextLink>
  );
};

export type IExternalLinkProps = Pick<
  typeof Anchor,
  'defaultProps'
>['defaultProps'];

export const ExternalLink: FC<AnchorProps> = ({ children, ...props }) => {
  const { isEnabled } = useContext(ODLExperimentContext);
  if (!isEnabled) {
    return (
      <StyledCircuitExternalLink {...props} variant="highlight">
        {children}
      </StyledCircuitExternalLink>
    );
  }
  return (
    <StyledExternalLink {...props} variant="highlight">
      {children}
    </StyledExternalLink>
  );
};
