import { optimizely } from 'shared/services/optimizely';
import {
  SignupEventCustomData,
  PurchaseEventCustomData,
  GoToCheckoutCustomData,
  SkipShopEventCustomData,
  MoreInfoCustomData,
} from 'shared/services/tracker/types';

enum OptimizelyEventNames {
  SIGNUP = 'signup',
  LOGIN = 'login',
  PURCHASE = 'purchase',
  GO_TO_ACTIVATION = 'go_to_activation',
  GO_TO_CHECKOUT = 'go_to_checkout',
  ADDRESS_SUGGESTION_SELECTED = 'address_suggestion_selected',
  SKIP_SHOP = 'skip_shop',
  MORE_INFO = 'more_info_click',
  PROCEED_AS_REGISTERED_USER = 'proceed_as_registered_user',
  GO_TO_PAYMENT = 'go_to_payment',
}

/**
 * SIGNUP handler
 * @param customData
 */
export function signupHandler(customData: SignupEventCustomData): void {
  const { merchantCode } = customData;
  optimizely.track(OptimizelyEventNames.SIGNUP, {
    merchantCode,
  });
}

/**
 * LOGIN handler
 * @param customData
 */
export function loginHandler(customData: SignupEventCustomData): void {
  if (customData?.merchantCode) {
    optimizely.track(OptimizelyEventNames.LOGIN, {
      merchantCode: customData?.merchantCode,
    });
  } else {
    optimizely.track(OptimizelyEventNames.LOGIN);
  }
}

/**
 * PURCHASE handler
 * @param customData
 */
export function purchaseHandler(customData: PurchaseEventCustomData): void {
  const { merchantCode, order, paymentMethod, userId } = customData;

  const optimizelyEventTags = {
    products: order.products.reduce(
      (a, { id, trackingId }) => `${a}${trackingId || id}|`,
      '|',
    ),
    productsQuantity: order.products.reduce(
      (a, { quantity }) => a + quantity,
      0,
    ),
    paymentType: paymentMethod,
    merchantCode,
    revenue: Math.floor(order.total * 100),
    order_id: order.id,
  };

  optimizely.track(
    OptimizelyEventNames.PURCHASE,
    userId,
    undefined,
    optimizelyEventTags,
  );
}

export function goToCheckoutHandler(customData: GoToCheckoutCustomData): void {
  const { shopExperience } = customData;

  optimizely.track(OptimizelyEventNames.GO_TO_CHECKOUT, {
    shopExperience,
  });
}

export function proceedAsRegisteredUser(): void {
  optimizely.track(OptimizelyEventNames.PROCEED_AS_REGISTERED_USER);
}

/**
 * GO_TO_ACTIVATION handler
 * @param customData
 */
export function goToActivationHandler(): void {
  optimizely.track(OptimizelyEventNames.GO_TO_ACTIVATION);
}

export function addessSuggestionSelectedHandler(): void {
  optimizely.track(OptimizelyEventNames.ADDRESS_SUGGESTION_SELECTED);
}

export function skipShopHandler(
  customData: SkipShopEventCustomData = {},
): void {
  const { userId, label } = customData;
  if (userId && label) {
    optimizely.track(OptimizelyEventNames.SKIP_SHOP, userId, undefined, {
      product: label,
    });
  } else {
    optimizely.track(OptimizelyEventNames.SKIP_SHOP);
  }
}

export function moreInfoHandler(customData: MoreInfoCustomData): void {
  const { productName, userId } = customData;
  optimizely.track(OptimizelyEventNames.MORE_INFO, userId, undefined, {
    productName,
  });
}

export function goToPaymentHandler(): void {
  optimizely.track(OptimizelyEventNames.GO_TO_PAYMENT);
}
