export const isFunction = (value: unknown) => typeof value === 'function';

type ComposeArgs = (arg: unknown) => unknown;
export const compose =
  <T extends unknown, R extends unknown>(...fns: ComposeArgs[]) =>
  (initial: T): R =>
    fns.reduceRight((acc, cur) => cur(acc), initial) as R;

export const omit =
  (keys: string[]) => (sourceObj: { [name: string]: unknown }) =>
    Object.keys(sourceObj)
      .filter((key: string) => !keys.includes(key))
      .reduce((acc, cur) => {
        acc[cur] = sourceObj[cur];
        return acc;
      }, {});

export const pickBy = (
  sourceObj: { [name: string]: unknown },
  predicate: (val: unknown) => boolean,
) =>
  Object.keys(sourceObj).reduce((acc, cur) => {
    if (!predicate(sourceObj[cur])) {
      return acc;
    }

    acc[cur] = sourceObj[cur];
    return acc;
  }, {});
