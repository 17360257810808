type ListenerCallback = (data?: unknown) => void;

interface Listeners {
  [eventName: string]: ListenerCallback[];
}

interface ITracker {
  dispatch: (eventName: string, customData?: unknown) => Promise<void>;
  listen: (eventName: string, handler: ListenerCallback) => void;
}

const isProductionEnv =
  process.env.NODE_ENV === 'production' &&
  process.env.VERCEL_ENV !== 'development' &&
  process.env.VERCEL_ENV !== 'preview';

export function Tracker(): ITracker {
  const listeners: Listeners = {};

  const dispatch = async (
    eventName: string,
    customData?: unknown,
  ): Promise<void> => {
    /**
     * Send tracker dispatches to the end of the callstack.
     * This ensures that tracking events trigger only after other
     * modules instances are properly initialised.
     */
    await Promise.resolve();

    if (!listeners[eventName]) {
      // eslint-disable-next-line no-console
      console.warn(
        `There's no listener registered for the event ${eventName}.`,
      );
      return;
    }

    if (!isProductionEnv) {
      // eslint-disable-next-line no-console
      console.log(
        `[TRACKING]: ${JSON.stringify(
          {
            eventName,
            customData,
          },
          null,
          2,
        )}
          `,
      );
    }

    try {
      listeners[eventName].forEach((listener) => {
        listener(customData);
      });
    } catch (err: unknown) {
      // eslint-disable-next-line no-console
      console.warn(
        `[TRACKING] Error while executing listener for the event "${eventName}": `,
        err,
      );
    }
  };

  const listen = (eventName: string, callback: ListenerCallback): void => {
    if (!listeners[eventName]) {
      listeners[eventName] = [];
    }

    listeners[eventName].push(callback);
  };

  return {
    dispatch,
    listen,
  };
}
