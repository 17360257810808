import { FC } from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { useRouter } from 'next/router';

import { ReactComponent as Cart } from './assets/cart.svg';

import { InternalLink } from 'src/experiments/odl/components/Link';
import {
  cleanupQuery,
  getShopExperienceLink,
} from 'shared/utils/shop-experience-link';

const IconButton = styled(InternalLink)<{ quantity?: number }>(
  ({ theme, quantity }) => css`
    margin-right: 0;
    position: relative;
    overflow: visible;
    background-color: var(--cui-bg-subtle);
    padding: ${theme.spacings.byte};
    border-radius: ${theme.borderRadius.pill};
    border: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    ${quantity === 0 &&
    `
      pointer-events: none;
      cursor: default;
    `}

    ${quantity &&
    `
      ::before {
      box-sizing: content-box;
      z-index: ${theme.zIndex.tooltip};
      border: ${theme.borderWidth.mega} solid white;
      content: '${quantity}';
      color: var(--cui-fg-on-strong);
      width: ${theme.iconSizes.mega};
      height: ${theme.iconSizes.mega};
      background-color: var(--cui-bg-danger-strong);
      position: absolute;
      border-radius: ${theme.borderRadius.pill};
      top: -${theme.spacings.byte};
      right: -${theme.spacings.byte};
      display: flex;
      align-items: center;
      justify-content: center;
      }
    `}
  `,
);

const CartIcon = styled(Cart)(
  ({ theme }) => css`
    width: ${theme.iconSizes.mega};
    height: ${theme.iconSizes.mega};
    color: var(--cui-bg-subtle);
  `,
);
const ShoppingCartWrapper = styled.div(css`
  width: 100%;
  display: flex;
  justify-content: right;
`);

export interface ShoppingCartProps {
  quantity: number;
  id: string;
}

export const ShoppingCart: FC<ShoppingCartProps> = ({ quantity, id }) => {
  const router = useRouter();
  const { query } = router;
  return (
    <ShoppingCartWrapper>
      <IconButton
        quantity={quantity}
        aria-label="shopping cart"
        href={{
          pathname: getShopExperienceLink(`/cart/${id}`),
          query: cleanupQuery(query),
        }}
        data-testid="shopping-cart-btn"
      >
        <CartIcon />
      </IconButton>
    </ShoppingCartWrapper>
  );
};
