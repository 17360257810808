import { captureException, withScope } from '@sentry/nextjs';
import { AuthState } from '@sumup/react-nanoauth';
import axios, { AxiosResponse, AxiosError, AxiosRequestConfig } from 'axios';

type GetTokenFnType = () => Promise<string>;

export const configureTokenFetcher = (
  getToken: GetTokenFnType,
  authState: AuthState,
): number =>
  axios.interceptors.request.use((config: AxiosRequestConfig) =>
    getToken()
      .then((token) => {
        if (!token) {
          withScope((scope) => {
            scope.setTags({
              auth_type: 'SSO',
              pathname: window.location.pathname,
              auth_state: authState,
            });
            captureException(
              new Error(
                `[SSO] auth token is undefined when attaching it to the headers`,
              ),
            );
          });
          return config;
        }

        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, no-param-reassign
        config.headers.Authorization = `Bearer ${token}`;
        return config;
      })
      .catch((e) => {
        withScope((scope) => {
          scope.setTag('auth', 'SSO');
          captureException(e);
        });
        return config;
      }),
  );

export { axios };
export type { AxiosResponse, AxiosError };
