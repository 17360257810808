import {
  IFormTextFieldFields,
  IFormSelectorFields,
  IFormSelector,
  IFormTextField,
  IFormSubSelector,
} from 'shared/infra/contentful/contentful';

export type FormFieldOption = {
  id?: string;
  parent_id?: string;
  label: string;
  value: string;
  parent_value?: string;
  wikidata?: string;
  parent_wikidata?: string;
};

export enum AddressFieldTypes {
  shippingAddress = 'shippingAddress',
  billingAddress = 'billingAddress',
}

export type FormField = {
  name?: string;
  id: string;
  label?: string;
  type?: string;
  placeholder?: string;
  validationErrorMessage?: string;
  labelAdditionalInformation?: string;
  validationRuleRegex?: string;
  additionalValidationRuleRegex?: string;
  additionalValidationErrorMessage?: string;
  isFieldRequired?: boolean;
  isFieldDisabled?: boolean;
  isFullWidth?: boolean;
  options?: FormFieldOption[];
  subSelector?: IFormSubSelector;
  displayDependency?: IFormSelector | IFormTextField | undefined;
  explanation?: string;
};

export interface IAddressFormContent {
  title: string;
  formFields: (IFormTextFieldFields | IFormSelectorFields)[];
}

export interface IFormAddress {
  /**
   * id represents the address entity id from CommerceLayer.
   * When user enter website for the first time, we don't have
   * this id as we don't have any shipping or billing addresses
   * This id is populated when we're fetching order details from CL or when creating a new shipping or billing address.
   * Also, we're using this id to check if we should update(id exists) or create the address(id does not exists)
   */
  id?: string;
  type: AddressFieldTypes | string;
  firstName: string;
  lastName: string;
  companyName?: string;
  phoneNumber?: string;
  addressLine1?: string;
  addressLine2?: string;
  city: string;
  zipCode: string;
  state: string;
  country: string;
  streetName?: string;
  houseNumber?: string;
  fiscalCode?: string;
  vatId?: string;
  companyRegistrationNumber?: string;
}
