import { Reducer } from 'redux';

import {
  IOrderState,
  OrderTypes,
  OrderActions,
  OrderStatuses,
} from 'shared/store/order/types';

const buildOperationStatus = (
  type: IOrderState['operationStatus']['type'] = null,
  loading = false,
  productReference = '',
  showNotification = false,
) => ({
  type,
  loading,
  productReference,
  showNotification,
});

export const INITIAL_STATE: IOrderState = {
  orderDetails: {
    id: '',
    number: null,
    status: OrderStatuses.draft,
    guest: true,
    formattedTotalAmount: '',
    formattedSubtotalAmount: '',
    formattedTotalTaxAmount: '',
    formattedTotalAmountWithTaxes: '',
    formattedDiscountAmount: '',
    discountAmountCents: 0,
    couponCode: '',
    taxRate: 0,
    currencyCode: 'USD',
    totalAmountFloat: 0,
    totalAmountWithTaxesCents: 0,
    totalAmountWithTaxesFloat: 0,
    customerEmail: '',
    metadata: {},
  },
  coupon: {
    couponCode: '',
    loading: false,
    error: false,
  },
  products: [],
  operationStatus: buildOperationStatus(),
  error: false,
  loading: false,
  isBusinessAccountProposed: false,
  isBusinessAccountSelected: true,
};

export const order: Reducer<IOrderState> = (
  state = INITIAL_STATE,
  action: OrderActions,
) => {
  switch (action.type) {
    case OrderTypes.LOAD_ORDER_REQUEST:
      return { ...state, loading: true };
    case OrderTypes.LOAD_ORDER_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        orderDetails: { ...action.payload },
      };
    case OrderTypes.LOAD_ORDER_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
      };
    case OrderTypes.LOAD_PRODUCTS:
      return {
        ...state,
        products: action.payload,
      };
    case OrderTypes.ADD_PRODUCT_REQUEST:
      return {
        ...state,
        operationStatus: buildOperationStatus(
          'addProduct',
          true,
          action.payload.contentfulId,
        ),
        loading: true,
      };
    case OrderTypes.PROPOSE_BUSINESS_ACCOUNT:
      return {
        ...state,
        isBusinessAccountProposed: action.payload.value,
      };
    case OrderTypes.SELECT_BUSINESS_ACCOUNT:
      return {
        ...state,
        isBusinessAccountSelected: action.payload.value,
      };
    case OrderTypes.ADD_PRODUCT_SUCCESS:
      return {
        ...state,
        operationStatus: buildOperationStatus(),
        loading: false,
        error: false,
      };
    case OrderTypes.ADD_PRODUCT_FAILURE:
      return {
        ...state,
        operationStatus: buildOperationStatus(),
        loading: false,
        error: true,
      };
    case OrderTypes.CHANGE_PRODUCT_QUANTITY:
      return {
        ...state,
        operationStatus: buildOperationStatus(
          'changeProductQuantity',
          true,
          action.payload.contentfulId,
        ),
        loading: true,
      };
    case OrderTypes.CHANGE_PRODUCT_QUANTITY_SUCCESS:
      return {
        ...state,
        operationStatus: buildOperationStatus(),
        loading: false,
        error: false,
      };
    case OrderTypes.CHANGE_PRODUCT_QUANTITY_FAILURE:
      return {
        ...state,
        operationStatus: buildOperationStatus(),
        loading: false,
        error: true,
      };
    case OrderTypes.REMOVE_PRODUCT:
      return {
        ...state,
        operationStatus: buildOperationStatus(
          'removeProduct',
          true,
          action.payload.contentfulId,
        ),
        loading: true,
      };
    case OrderTypes.REMOVE_PRODUCT_SUCCESS:
      return {
        ...state,
        operationStatus: buildOperationStatus(),
        loading: false,
        error: false,
      };
    case OrderTypes.REMOVE_PRODUCT_FAILURE:
      return {
        ...state,
        operationStatus: buildOperationStatus(),
        loading: false,
        error: true,
      };
    case OrderTypes.PLACE_ORDER_REQUEST:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case OrderTypes.PLACE_ORDER_SUCCESS:
      return {
        ...state,
        orderDetails: {
          ...state.orderDetails,
          ...action.payload.order,
        },
        loading: false,
        error: false,
      };
    case OrderTypes.PLACE_ORDER_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
      };
    case OrderTypes.CLONE_ORDER_REQUEST:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case OrderTypes.CLONE_ORDER_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
      };
    case OrderTypes.CLONE_ORDER_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
      };
    case OrderTypes.APPLY_COUPON_REQUEST:
      return {
        ...state,
        coupon: {
          ...state.coupon,
          loading: true,
          error: false,
        },
      };
    case OrderTypes.APPLY_COUPON_SUCCESS:
      return {
        ...state,
        orderDetails: { ...action.payload.order },
        coupon: {
          couponCode: action.payload.order.couponCode,
          loading: false,
          error: false,
        },
      };
    case OrderTypes.APPLY_COUPON_FAILURE:
      return {
        ...state,
        coupon: {
          couponCode: '',
          loading: false,
          error: true,
        },
      };
    case OrderTypes.RESET_COUPON_REQUEST:
      return {
        ...state,
        coupon: {
          couponCode: '',
          loading: false,
          error: false,
        },
      };
    case OrderTypes.UPDATE_NOTIFICATION:
      return {
        ...state,
        operationStatus: buildOperationStatus(
          action.payload.type,
          undefined,
          undefined,
          action.payload.showNotification,
        ),
      };
    case OrderTypes.RESET_ORDER:
      return INITIAL_STATE;
    default:
      return state;
  }
};
