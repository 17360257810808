import { createContext, FunctionComponent, ReactNode, useContext } from 'react';

import { ShopExperience } from 'shared/constants/ShopExperience';
import { getShopExperienceFromSource } from 'shared/utils/shop-experience-link';

const ShopExperienceContext = createContext(getShopExperienceFromSource());

export const ShopExperienceProvider: FunctionComponent<{
  children: ReactNode;
}> = ({ children }) => (
  <ShopExperienceContext.Provider value={getShopExperienceFromSource()}>
    {children}
  </ShopExperienceContext.Provider>
);

export const useShopExperience = (): ShopExperience =>
  useContext<ShopExperience>(ShopExperienceContext);

export default ShopExperienceContext;
