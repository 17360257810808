import { BatchSpanProcessor, Span } from '@opentelemetry/sdk-trace-base';
import { WebTracerProvider } from '@opentelemetry/sdk-trace-web';
import { XMLHttpRequestInstrumentation } from '@opentelemetry/instrumentation-xml-http-request';
import { OTLPTraceExporter } from '@opentelemetry/exporter-trace-otlp-http';
import { ZoneContextManager } from '@opentelemetry/context-zone';
import { registerInstrumentations } from '@opentelemetry/instrumentation';
import { Resource } from '@opentelemetry/resources';
import { SemanticResourceAttributes } from '@opentelemetry/semantic-conventions';

import isServer from 'shared/utils/is-server';
import { CLAYER_ORDER_ID, MERCHANT_ID } from 'shared/infra/storage';
import {
  retrieveGlobalCatalog,
  retrieveGlobalLocale,
} from 'shared/services/OrderInformationService.globals';
import { setOrgSpanAttributes } from 'shared/infra/api/opentelemetry/utils';

const applyCustomAttributesOnSpan = (span: Span) => {
  span.setAttributes({
    'sumup.merchant.code': localStorage.getItem(MERCHANT_ID) || '',
    'sumup.order.id': localStorage.getItem(CLAYER_ORDER_ID) || '',
    'sumup.shop.locale': retrieveGlobalLocale() || '',
    'sumup.x.flow': retrieveGlobalCatalog() || '',
  });
  setOrgSpanAttributes(span);
};

if (!isServer && process.env.NEXT_PUBLIC_OTEL_COLLECTOR_URL) {
  const provider = new WebTracerProvider({
    resource: new Resource({
      [SemanticResourceAttributes.SERVICE_NAME]:
        process.env.NEXT_PUBLIC_OTEL_SERVICE_NAME,
    }),
  });

  const token = btoa(
    [
      process.env.NEXT_PUBLIC_OTEL_COLLECTOR_USERNAME,
      process.env.NEXT_PUBLIC_OTEL_COLLECTOR_PASSWORD,
    ].join(':'),
  );

  const exporter = new OTLPTraceExporter({
    url: process.env.NEXT_PUBLIC_OTEL_COLLECTOR_URL,
    headers: {
      Authorization: `Basic ${token}`,
    },
  });

  // @ts-ignore
  provider.addSpanProcessor(new BatchSpanProcessor(exporter));

  provider.register({
    contextManager: new ZoneContextManager().enable(),
  });

  const allowedUrlsForTracingRegExp = [
    'https?://sumup.commercelayer.io.*',
    // TODO revisit tracing internal calls
    // 'https?://api.sumup.com.*',
    // 'https?://api.sam-app.ro.*',
  ];

  registerInstrumentations({
    instrumentations: [
      new XMLHttpRequestInstrumentation({
        // TODO - ignoreUrls
        // exclude tracing
        // anything that's not inside our app origin + /api
        // anything that's not commercelayer
        // anything that's not towards our internal services
        propagateTraceHeaderCorsUrls: [
          // local domain requests attach tracing headers by default
          ...allowedUrlsForTracingRegExp.map(
            (expression) => new RegExp(expression),
          ),
        ],
        applyCustomAttributesOnSpan,
      }),
    ],
  });
}
