import { createContext, FunctionComponent, ReactNode, useContext } from 'react';

export const ContentContext = createContext({});

export const ContentProvider: FunctionComponent<{
  content: unknown;
  children: ReactNode;
}> = ({ children, content }) => {
  return (
    <ContentContext.Provider value={content}>
      {children}
    </ContentContext.Provider>
  );
};

export default ContentContext;

export const useContent = (): unknown => useContext<unknown>(ContentContext);
