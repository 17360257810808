import { IPaymentMethod } from 'shared/infra/commerceLayer/types';

export enum IPaymentActionTypes {
  SET_LOADING = '@payment/SET_LOADING',
  SET_ERROR = '@payment/SET_ERROR',

  ATTACH_PAYMENT_METHOD_REQUEST = '@payment/ATTACH_PAYMENT_METHOD_REQUEST',
  ATTACH_PAYMENT_METHOD_SUCCESS = '@payment/ATTACH_PAYMENT_METHOD_SUCCESS',
  ATTACH_PAYMENT_METHOD_FAILURE = '@payment/ATTACH_PAYMENT_METHOD_FAILURE',

  ATTACH_CHECKOUT_ID = '@payment/ATTACH_CHECKOUT_ID',
  ATTACH_PAYMENT_CODE = '@payment/ATTACH_PAYMENT_CODE',
  ATTACH_DOWNLOAD_URL = '@payment/ATTACH_DOWNLOAD_URL',
}

export type IPaymentState = {
  id: string;
  reference: IPaymentMethod;
  checkoutId: string;
  paymentCode: string;
  documentDownloadUrl: string;
};

export interface ISetPaymentLoadingAction {
  type: IPaymentActionTypes.SET_LOADING;
  payload: { isLoading?: boolean };
}
export interface ISetPaymentErrorAction {
  type: IPaymentActionTypes.SET_ERROR;
  payload: { error: string };
}

export interface IAttachPaymentMethod {
  type: IPaymentActionTypes.ATTACH_PAYMENT_METHOD_REQUEST;
}

export interface IAttachPaymentMethodSuccess {
  type: IPaymentActionTypes.ATTACH_PAYMENT_METHOD_SUCCESS;
  payload: Pick<IPaymentState, 'id' | 'reference'>;
}

export interface IAttachPaymentMethodFailure {
  type: IPaymentActionTypes.ATTACH_PAYMENT_METHOD_FAILURE;
  payload: { error: string };
}

export interface IAttachCheckoutId {
  type: IPaymentActionTypes.ATTACH_CHECKOUT_ID;
  payload: { checkoutId: string };
}

export interface IAttachPaymentCode {
  type: IPaymentActionTypes.ATTACH_PAYMENT_CODE;
  payload: { code: string };
}

export interface IAttachDocumentDownloadUrl {
  type: IPaymentActionTypes.ATTACH_DOWNLOAD_URL;
  payload: { documentDownloadUrl: string };
}

export type IPaymentActions =
  | ISetPaymentLoadingAction
  | ISetPaymentErrorAction
  | IAttachPaymentMethod
  | IAttachPaymentMethodSuccess
  | IAttachPaymentMethodFailure
  | IAttachCheckoutId
  | IAttachPaymentCode
  | IAttachDocumentDownloadUrl;
