export const SALES_CHANNEL_AUTH_TOKEN = 'SALES_CHANNEL_AUTH_TOKEN';
export const CLAYER_ORDER_ID = 'CLAYER_ORDER_ID';
export const ORDER_LOCALE = 'ORDER_LOCALE';
export const ORDER_CATALOG = 'ORDER_CATALOG';
export const MERCHANT_CODE = 'MERCHANT_CODE';
export const MERCHANT_ID = 'MERCHANT_ID';
export const MERCHANT_PROFILE = 'MERCHANT_PROFILE';
export const SALES_CHANNEL_COUNTRY = 'SALES_CHANNEL_COUNTRY';
export const PLACED_ORDER_PRODUCTS = 'PLACED_ORDER_PRODUCTS';
export const PLACED_ORDER_INFO = 'PLACED_ORDER_INFO';
export const PLACED_PAYMENT_REFERENCE = 'PLACED_PAYMENT_REFERENCE';
