import { Button as CircuitButton, ButtonProps } from '@sumup/circuit-ui';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { FC, useContext } from 'react';

import { ODLExperimentContext } from '../context';

type Props = ButtonProps;

const BlackPrimary = styled(CircuitButton)(
  ({ theme }) => css`
    background-color: ${theme.colors.black};
    color: ${theme.colors.white};
    border-color: ${theme.colors.black};
    &:hover {
      background-color: #666666;
      border-color: #666666;
      color: ${theme.colors.white};
    }
    &:active {
      background-color: ${theme.colors.black};
      border-color: ${theme.colors.black};
    }
    &[disabled],
    &:disabled {
      background-color: #f5f5f5;
      color: #cccccc;
      border: none;
    }
    :focus-visible,
    :focus {
      border: 1px solid ${theme.colors.white};
      box-shadow: 0px 0px 0px 3px ${theme.colors.black};
    }
  `,
);

const BlackSecondary = styled(CircuitButton)(
  ({ theme }) => css`
    background-color: ${theme.colors.n100};
    color: ${theme.colors.black};
    border-color: ${theme.colors.n100};
    &:hover {
      background-color: #e6e6e6;
      border-color: #e6e6e6;
    }
    &:active {
      background-color: ${theme.colors.n100};
      border-color: ${theme.colors.n100};
    }
    &:disabled {
      background-color: #f5f5f5;
      color: #cccccc;
    }
    &:focus-visible {
      outline-color: ${theme.colors.black};
      box-shadow: none;
    }

    &:focus {
      border-color: ${theme.colors.black};
      box-shadow: none;
    }
  `,
);

export const Button: FC<Props> = ({ children, variant, ...props }) => {
  const { isEnabled } = useContext(ODLExperimentContext);
  if (!isEnabled || variant === 'tertiary') {
    return (
      <CircuitButton variant={variant} {...props}>
        {children}
      </CircuitButton>
    );
  }

  if (variant === 'primary') {
    return <BlackPrimary {...props}>{children}</BlackPrimary>;
  }

  if (!variant || variant === 'secondary') {
    return <BlackSecondary {...props}>{children}</BlackSecondary>;
  }
};
