import { ReactNode } from 'react';

import { ShopHeader } from 'shared/containers/ShopHeader';

export interface LayoutProps {
  children?: ReactNode;
}

export const Layout = ({ children }: LayoutProps): JSX.Element => (
  <>
    <ShopHeader />
    {children}
  </>
);
