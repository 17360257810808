import { stringify, parse } from 'query-string';

import { getItem, MERCHANT_CODE } from 'shared/infra/storage';
import { compose, omit, pickBy, isFunction } from 'shared/utils/functional';

function getCleanLocation() {
  const QUERY_PARAM_NOT_ALLOWED = ['email'];
  const { pathname, search } = window.location;

  const safeQueryString = compose(
    stringify,
    omit(QUERY_PARAM_NOT_ALLOWED),
    parse,
  )(search) as string;

  return safeQueryString.length ? `${pathname}?${safeQueryString}` : pathname;
}

export const send = (customData: Record<string, unknown>) => {
  // eslint-disable-next-line @typescript-eslint/unbound-method
  if (window.dataLayer && isFunction(window.dataLayer.push)) {
    const merchantCode = getItem(MERCHANT_CODE);

    const data = pickBy(
      { ...customData, Merchant_Code: merchantCode },
      (value) => !!value,
    );

    window.dataLayer.push({ ...data });
  }
};

interface InteractionEventFields {
  category?: string;
  label?: string;
  [custom: string]: unknown;
}
export function sendEvent({
  category,
  label = '',
  ...customFields
}: InteractionEventFields): void {
  send({
    'event': 'interaction',
    'target': category,
    'target-properties': label,
    ...customFields,
  });
}

interface PageViewOverrides {
  ['content-name']?: string;
  [custom: string]: unknown;
}
export function sendPageview(overrides?: PageViewOverrides) {
  send({
    'content-name': getCleanLocation(),
    ...overrides,
    'event': 'content-view',
  });
}

export function parsePrice(price: string): number {
  return parseFloat(price.replace(/[^0-9-.]/g, ''));
}
