import { ReactNode, useEffect, useState, useMemo } from 'react';
import {
  AuthProvider as NanoAuthProvider,
  createAxiosClient,
  useAuth,
} from '@sumup/react-nanoauth';

import { getAuthCallbackUrl, getAuthLogoutUrl } from './AuthService';

import { configureTokenFetcher } from 'shared/infra/http';

const handleError = (err) => {
  // eslint-disable-next-line no-console
  console.error(err, '[AUTH] authentication error');
};

const oidcClientOptions = {
  createHTTPClient: createAxiosClient,
};

const AuthEffects = () => {
  const { getAccessToken, authState } = useAuth();
  const [isConfigured, setConfigured] = useState(false);

  useEffect(() => {
    if (!isConfigured) {
      configureTokenFetcher(getAccessToken, authState);
      setConfigured(true);
    }
  }, [getAccessToken, authState, isConfigured]);

  return null;
};

/**
 * A wrapper around the react-nanoauth AuthProvider enabling
 * feature toggling.
 */
const AuthProvider = ({ children }: { children: ReactNode }): JSX.Element => {
  const oidcConfig = useMemo(
    () => ({
      issuer: process.env.NEXT_PUBLIC_OIDC_ISSUER,
      clientId: process.env.NEXT_PUBLIC_OIDC_CLIENT_ID,
      redirectURI: getAuthCallbackUrl(),
      postLogoutRedirectURI: getAuthLogoutUrl(),
      scopes: ['openid', 'offline', 'classic', 'email'],
    }),
    [],
  );

  return (
    <NanoAuthProvider
      onError={handleError}
      oidcConfig={oidcConfig}
      oidcClientOptions={oidcClientOptions}
    >
      <AuthEffects />
      {children}
    </NanoAuthProvider>
  );
};

export { AuthProvider };
