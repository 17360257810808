import { IProductGalleryItem } from 'shared/infra/commerceLayer/prices';

/**
 * ActionType types
 */
export enum CatalogTypes {
  PROVIDE_CATALOG = '@catalog/PROVIDE_CATALOG',
}

/**
 * Action Creator types
 */
/* Actions to handle the Catalog */
export interface IProvideCatalogAction {
  type: CatalogTypes.PROVIDE_CATALOG;
  payload: ICatalogState;
}

export type CatalogActions = IProvideCatalogAction;

/**
 * State types
 */
export interface ICatalogState {
  readonly [contentfulReferenceId: string]: IProductGalleryItem;
}
