import fs from 'fs';
import { tmpdir } from 'os';
import { join } from 'path';

import { getSalesChannelToken } from '@commercelayer/js-auth';

import { CLAYER_ORG } from '../constants';

export interface ISalesChannelAuthResponse {
  accessToken?: string;
  expiresIn?: number;
  errorMessage?: string;
  createdAt?: number;
  isTokenExpired?: () => boolean;
}

export const salesChannelAuthUncached = async (
  scope: string,
  commerceLayerClient: string,
): Promise<ISalesChannelAuthResponse> => {
  const CLAYER_AUTH_CREDENTIALS = {
    client_id: commerceLayerClient,
    endpoint: `https://${CLAYER_ORG}.commercelayer.io`,
  };

  let response: ISalesChannelAuthResponse = {};

  try {
    const token = await getSalesChannelToken({
      clientId: CLAYER_AUTH_CREDENTIALS.client_id,
      endpoint: CLAYER_AUTH_CREDENTIALS.endpoint,
      scope,
    });

    const { data } = token;
    response = {
      isTokenExpired: token.expired.bind(token),
      accessToken: data.access_token,
      // [FIXME] why we declare different type from getSalesChannelToken
      expiresIn: data.expires_in as unknown as number,
      createdAt: data.created_at as unknown as number,
    };
  } catch (error: unknown) {
    response = { errorMessage: 'CL_UNAUTHORIZED' };
    throw error;
  }

  return response;
};

export const salesChannelAuthCached = async (
  scope: string,
  commerceLayerClient: string,
): Promise<ISalesChannelAuthResponse> => {
  const parentFolder = join(tmpdir(), 'cl-storage');
  const cacheFilePath = join(parentFolder, `${scope}_token.json`);

  if (!fs.existsSync(parentFolder)) {
    fs.mkdirSync(parentFolder);
  }

  // check if token file already exists. if so, lock.
  if (!fs.existsSync(cacheFilePath)) {
    // lock file before request by creating it in order to lock all other requests.
    fs.writeFileSync(cacheFilePath, JSON.stringify(null));

    const authRes = await salesChannelAuthUncached(scope, commerceLayerClient);

    // deletes isTokenExpired method as it's not needed for build
    if (authRes.isTokenExpired) {delete authRes.isTokenExpired;}

    fs.writeFileSync(cacheFilePath, JSON.stringify(authRes));

    return authRes;
  }

  const data = fs.readFileSync(cacheFilePath, { encoding: 'utf8' });
  const res: ISalesChannelAuthResponse = JSON.parse(
    data,
  ) as ISalesChannelAuthResponse;

  if (res == null) {
    // Wait to avoid API throttling (cause: parallel requests for different scopes)
    await sleep(500);

    return salesChannelAuthCached(scope, commerceLayerClient);
  }

  const currentDateInSeconds = Math.ceil(new Date().getTime() / 1000);
  const createdDateInSeconds = Math.floor(
    fs.statSync(cacheFilePath).birthtimeMs / 1000,
  );

  /**
   * Token is expired if number of seconds this token is available is more than
   * difference between the current time and the time cache file was created.
   * 10 seconds is a random offset, just avoid tokens that expire nearly now
   */
  const isExpired =
    res.expiresIn < currentDateInSeconds - createdDateInSeconds + 10;

  if (isExpired) {
    fs.unlinkSync(cacheFilePath);
    return salesChannelAuthCached(scope, commerceLayerClient);
  }

  return res;
};

export const salesChannelAuth = (
  scope: string,
  commerceLayerClient: string,
): Promise<ISalesChannelAuthResponse> => {
  const isBrowser = process.browser;
  const isProduction = process.env.NODE_ENV === 'production';
  if (isBrowser || !isProduction) {
    return salesChannelAuthUncached(scope, commerceLayerClient);
  }

  // It's necessary to cache authentication tokens during the build.
  // We otherwise hit auth rate limits from Commercelayer.
  return salesChannelAuthCached(scope, commerceLayerClient);
};

function sleep(ms: number): Promise<unknown> {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
}
