
import { pythia } from '../services';

import { PythiaEvents, PythiaStatuses } from './types';

import { EVENTS } from 'shared/services/tracker/types';

export const PYTHIA_EVENTS: PythiaEvents = {
  [EVENTS.CREATE_CHECKOUT]: {
    name: 'create_checkout',
    status: PythiaStatuses.SUCCESSFUL,
  },
  [EVENTS.CREATE_CHECKOUT_FAILURE]: {
    name: 'create_checkout',
    status: PythiaStatuses.FAILED,
  },
  [EVENTS.PAYMENT_CALLBACK_INVOKED]: {
    name: 'payment_callback_invoked',
  },
};

export const generateCreateCheckout =
  (event: EVENTS) =>
  (data = {}): void =>
    pythia.send({
      counterMetrics: [
        {
          name: PYTHIA_EVENTS[event].name,
          labels: {
            status: PYTHIA_EVENTS[event].status,
            ...data,
          },
        },
      ],
    });
export const handleCreateCheckout = generateCreateCheckout(
  EVENTS.CREATE_CHECKOUT,
);
export const handleCreateCheckoutFailure = generateCreateCheckout(
  EVENTS.CREATE_CHECKOUT_FAILURE,
);

export const handlePaymentCallbackInvoked = (data = {}): void =>
  pythia.send({
    counterMetrics: [
      {
        name: PYTHIA_EVENTS[EVENTS.PAYMENT_CALLBACK_INVOKED].name,
        labels: {
          ...data,
        },
      },
    ],
  });

export const handleAddressSearchError = (data = {}): void =>
  pythia.send({
    counterMetrics: [
      {
        name: PYTHIA_EVENTS[EVENTS.SEARCH_ADDRESS_FAILURE].name,
        labels: {
          ...data,
        },
      },
    ],
  });
